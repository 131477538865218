<template>
  <div class="home bg-gray-100">
    <div class="mb-8">
    <Header />
    </div>
    <div class="w-1/4 p-4">
      <Dropdown
        placeholder="Select an option"
        :options="options"
        :fullWidth="true"
        :selectedOption="selectedDropdownOption"
        @selection="changeColor"/>
    </div>
    <div class="w-1/4 p-4">
      <Dropdown
        placeholder="Select an option"
        :options="options"
        :fullWidth="true"
        @selection="changeColor"
        :disabled="true"/>
    </div>
    <div class="w-1/4 p-4">
      <TextInput id="firstName" label="First Name" :value="firstName" @input="updateName" />
    </div>
    <div class="w-1/4 p-4">
      <TextInput id="search" placeholder="Search..." label="Search" :value="searchValue"
                 @input="updateSearch">
        <template v-slot:icon>
          <SearchIcon />
        </template>
      </TextInput>
    </div>
    <div class="w-1/4 p-4">
      <TextArea id="bio" label="Biography" :value="textArea" @input="updateTextArea"/>
    </div>
    <div class="w-full p-4">
      <span class="mr-4">
        <Button type="primary">
          Submit
        </Button>
      </span>
      <span class="mr-4">
        <Button type="secondary">
          Submit
        </Button>
      </span>
      <span class="mr-4">
        <Button type="text">
          Submit
        </Button>
      </span>
      <span class="mr-4">
        <Button type="text" disabled>
          Submit
        </Button>
      </span>
    </div>
    <div class="w-full p-4">
      <span class="mr-4">
        <Checkbox id="checkbox1" label="Unchecked" value="Checkbox 1"
                v-model="checkboxGroup" />
      </span>
      <span class="mr-4">
        <Checkbox id="checkbox2" label="Checked" value="Checkbox 2"
                v-model="checkboxGroup" />
      </span>
      <span class="mr-4">
      <Checkbox id="checkbox3" label="Checked" value="Checkbox 3"
                v-model="checkbox"/>
      </span>
      <span class="mr-4">
        <Checkbox id="checkbox4" label="Checked" value="Checkbox 4"
                v-model="checkbox"
                disabled/>
      </span>
      <span class="mr-4">
        <Checkbox id="checkbox5" label="Unchecked" value="Checkbox 5"
                v-model="checkboxGroup"
                disabled/>
      </span>
      <div>
        <div class="inline-block border-2 bg-gray-300 text-gray-600 border-dashed border-gray-600
                    p-2 m-2">
          <strong>Checkboxes 1 & 2:</strong> {{ checkboxGroup }}
          <br>
          <strong>Checkbox 3:</strong> {{ checkbox }}
        </div>
      </div>
    </div>
    <div class="w-full p-4">
      <span class="mr-4">
        <RadioButton id="radio1" label="Radio 1" value="Radio 1" name="123"
                v-model="radioGroup" />
      </span>
      <span class="mr-4">
        <RadioButton id="radio2" label="Radio 2 - Selected" value="Radio 2" name="123"
                v-model="radioGroup" />
      </span>
      <span class="mr-4">
        <RadioButton id="radio3" label="Radio 3" value="Radio 3" name="123"
                v-model="radioGroup" />
      </span>
      <span class="mr-4">
        <RadioButton id="radio4" label="Radio A - Selected" value="Radio A" name="abc"
                v-model="radioGroup2"
                disabled />
      </span>
      <span class="mr-4">
        <RadioButton id="radio5" label="Radio B" value="Radio B" name="abc"
                v-model="radioGroup2"
                disabled />
      </span>
    </div>
    <div class="flex justify-center w-1/2 mx-auto pt-4 pb-12">
      <PhotoUploadInlineForm></PhotoUploadInlineForm>
    </div>
  </div>
</template>

<script>
import cssVars from 'css-vars-ponyfill';
import Dropdown from '@/components/Dropdown.vue';
import TextInput from '@/components/TextInput.vue';
import Button from '@/components/Button.vue';
import SearchIcon from '@/assets/search.svg';
import TextArea from '@/components/TextArea.vue';
import Checkbox from '@/components/Checkbox.vue';
import RadioButton from '@/components/RadioButton.vue';
import Header from '@/components/Header.vue';
import PhotoUploadInlineForm from '@/components/PhotoUploadInlineForm.vue';

export default {
  name: 'Home',
  components: {
    Dropdown,
    TextInput,
    Button,
    SearchIcon,
    TextArea,
    Checkbox,
    RadioButton,
    Header,
    PhotoUploadInlineForm,
  },
  data() {
    return {
      options: [
        {
          label: 'Red',
          value: '255, 0, 0',
        },
        {
          label: 'Blue',
          value: '0, 0, 255',
        },
        {
          label: 'Green',
          value: '0, 255, 0',
        },
      ],
      firstName: '',
      searchValue: '',
      textArea: '',
      selectedDropdownOption: {},
      checkboxGroup: ['Checkbox 2'],
      checkbox: true,
      radioGroup: 'Radio 2',
      radioGroup2: 'Radio A',
    };
  },
  methods: {
    updateName(name) {
      this.firstName = name;
    },
    updateSearch(search) {
      this.searchValue = search;
    },
    updateTextArea(text) {
      this.textArea = text;
    },
    changeColor(selection) {
      cssVars({
        variables: {
          '--primary': selection.value,
        },
      });
      this.selectedDropdownOption = selection;
    },
  },
};
</script>

<style>

</style>
